import React, { useState } from 'react';
import { Grid, Typography, Button } from '@mui/material';
import styles from '../../Login/components/Login.module.scss';
import { translate } from '../../../utils/localization/translate';
import useProjectService from '../consumers/useProjectService';

export const PictureLockWarningForm = ({ id, closeForm, refetchData }) => {
  const { t } = translate();
  const { setPictureLock } = useProjectService();
  const [isSubmitting, setIsSubmiting] = useState(false);

  const handleSetPictureLock = async () => {
    setIsSubmiting(true);
    const jsonBody = {
      projectId: id,
      // prod notary
      // notaryId: '0xF01d65F53F0c9ff3e869e2df3156b5B727edC727',
      // dev notary
      notaryId: '0xcEf534af227232507d863c631fE79450F72b462f',
    };

    try {
      await setPictureLock(jsonBody);
      setIsSubmiting(false);
    } catch (e) {
      setIsSubmiting(false);
    }
    closeForm();
    refetchData();
  };

  return (
    <div className={styles.root}>
      <Grid className={styles.formContainer} container spacing="1" alignItems="center">
        <Typography variant="contained" align="center" sx={{ color: 'text.primary', mt: 3, mb: 3, fontWeight: 'bold' }}>
          {t(
            'You are about to set picture lock on this project, Make sure to assign yourself to a project to be a part of the project. You cannot make changes after continue'
          )}
        </Typography>

        <div className={styles.buttonGroup}>
          <Button variant="contained" className={styles.closeModalButton} fullWidth onClick={() => closeForm()}>
            {t('Cancel')}
          </Button>

          <Button
            className={styles.buttonPrimary}
            type="submit"
            variant="contained"
            onClick={handleSetPictureLock}
            fullWidth
            disabled={isSubmitting}
          >
            {t('Continue')}
          </Button>
        </div>
      </Grid>
    </div>
  );
};
