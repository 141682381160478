import {
    Card,
    Typography,
    Stack,
    Button,
} from '@mui/material';
import { useEffect, useState } from "react";
import Iconify from '../../../components/Iconify/Iconify';
import useFileUpload from '../../../utils/hooks/useFileUpload';
import { translate } from '../../../utils/localization/translate';
import useProjectService from "../consumers/useProjectService";
import { AddUsersForm } from './AddUsersForm';
import { OwnerUserTable } from "./OwnerUserTable";
import styles from "./ProjectDetailsComponent.module.scss";
import { ProjectItemComponent } from "./ProjectItemComponent";

export const ProjectDetailsComponent = ({ project, user }) => {
    const { t } = translate();
    const [users, setUsers] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { getUsersForProjectOwner } = useProjectService();
    const [isUserOwner, setIsUserOwner] = useState(user.role === 'owner');
    const { getPoster } = useFileUpload();
    const [poster, setPoster] = useState('');

    useEffect(() => {
        if (isOwner()) {
            getData();
        }

        fetchPoster();
    }, []);

    const fetchPoster = async () => {
        const poster = await getPoster(project.projectaddress);

        setPoster(poster);
    }

    const getData = async () => {
        setIsLoading(true);

        try {
            const users = await getUsersForProjectOwner(project.projectId);
            console.log("users", users);
            console.log("project", project);
            setUsers(users);
            setIsLoading(false);
        } catch (e) {
            setIsUserOwner(false);
            setIsLoading(false);
        }
    }

    const getProjectStatus = () => {
        switch (project.projectDetails.projectStage) {
            case 0:
                return t('In production');
            case 1:
                return t('Finished');
            default:
                return '';
        }
    }

    const getStatusClass = () => {
        switch (project.projectDetails.projectStage) {
            case 0:
                return styles.inProduction;
            case 1:
                return styles.finished;
            default:
                return '';
        }
    }

    const isOwner = () => {
        return user.role === 'owner';
    }

    const handleOpenModal = () => {
        setIsModalOpen(true);
    }

    const handleBackgroundClick = (e) => {
        const modal = document.getElementById('modal');
        if (e.target === modal) {
            setIsModalOpen(false);
        }
    }

    const getOwnerContent = () => {
        if (isUserOwner && users) {
            return (
                <>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mt={8}>
                        <Typography variant="h5" gutterBottom>{t('Project members')}</Typography>
                        <Button variant="contained" className={styles.buttonPrimary} startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => handleOpenModal()} disabled={getProjectStatus() === 'Finished'}>
                            {t('Add Member')}
                        </Button>
                    </Stack>
                    <OwnerUserTable projectId={project.projectId} userList={users} refetchData={getData} isProjectFinished={getProjectStatus() === 'Finished'} />

                    {isModalOpen &&
                        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <div className={styles.modal} id='modal' onClick={(e) => handleBackgroundClick(e)}>
                            <div className={`${styles.modalContainer} ${styles.userModalContainer}`}>
                                <AddUsersForm projectId={project.projectId} closeForm={() => setIsModalOpen(false)} refetchData={getData} />
                            </div>
                        </div>
                    }
                </>
            );
        }

        return '';
    }

    return (
        <>
            <div className={styles.detailsHeaderContainer}>
                <Card className={styles.imageCard}>
                    <div className={styles.imageContainer} style={{
                        backgroundImage: `url("${poster}")`
                    }} />
                </Card>
                <div className={styles.container}>
                    <Card className={styles.card}>
                        <Typography variant="subtitle1" gutterBottom>{t('Project details')}</Typography>
                        {project.projectDetails &&
                            <>
                                <ProjectItemComponent className={`${styles.status} ${getStatusClass()}`} label={t('Project status')} value={getProjectStatus()} />
                                <ProjectItemComponent label={t('Total project revenue')} value={project.projectDetails.totalProjectRevenue.toString().concat(" € ")} />
                            </>
                        }
                        <ProjectItemComponent linkTo={`https://testnet.ftmscan.com/address/${project.projectaddress}`} label={t('Project address')} value={`${project.projectaddress.substring(0, 4)}...${project.projectaddress.substring(project.projectaddress.length - 4, project.projectaddress.length)}`} />
                    </Card>
                    {project.projectDetails?.userTO &&
                        <Card className={styles.card}>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('Your revenue for this project')}
                            </Typography>
                            <ProjectItemComponent label={t('Pending revenue')} value={project.projectDetails.userTO.pendingRevenue.toString().concat(" € ")} />
                            <ProjectItemComponent label={t('Initial funding')} value={project.projectDetails.userTO.initialFunding.toString().concat(" € ")} />
                            <ProjectItemComponent label={t('Payed out')} value={project.projectDetails.userTO.paid.toString().concat(" € ")} />
                            <ProjectItemComponent label={t('Quota')} value={parseFloat(project.projectDetails.userTO.quota).toFixed(0)} />
                        </Card>
                    }
                </div>
            </div>
            {!isLoading && getOwnerContent()}
        </>
    );
}