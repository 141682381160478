import React from 'react';
import {
    Stack,
    Container
} from '@mui/material';
import styles from './components/TermsofServics.module.scss';
import Page from '../../components/Page/Page';

export default function TermsOfServiceView() {
    return (
        <Page>
            <Container>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <div>
                        <h1>Terms of Use – KAAATSCHING‼! the Platform for Fair and Transparent Revenue Share using Blockchain Technology</h1>
                        <section>
                        Effective Date: 26.08.2023 <br />
                        Last Updated: 26.08.2023 <br />
                        </section>
                        
                        <section>
                        <h2>1. Acceptance of Terms</h2>
                            <p>
                            Welcome to KAAATSCHING‼! the dApp for fair and transparent revenue share for all artists, crew members and investors (big or small) of a media project, by using Blockchain technology. By accessing or using our platform and fair revenue sharing services, you agree to comply with and be bound by these Terms of Use. If you do not agree with these terms, please do not use our services.
                            </p>
                        </section>

                        <section>
                            <h2>2. Use of Platform</h2>
                            <p>
                            <ol className={styles.ol} type="a">
                                <li><b>Content: </b>You may access the services on our platform solely for personal use, and in accordance with applicable laws and regulations.</li> 
                                <li><b>User Conduct: </b>You agree not to engage in any behavior that could disrupt the platform's functionality or infringe upon the rights of others. This includes refraining from uploading malicious software, violating copyright or intellectual property rights, or engaging in fraudulent activities.</li> 
                                <li><b>Age Restrictions: </b>You must be of legal age to use our services. If you are a minor, you must obtain parental or legal guardian consent to use the platform.</li> 
                            </ol>
                            </p>
                        </section>

                        <section>
                            <h2>3. Fair Revenue Share and Blockchain Technology</h2>
                            <p>
                            <ol className={styles.ol} type="a">
                                <li><b>Transparency: </b>We use blockchain technology to provide transparent revenue sharing. Revenue generated from content consumption through ticket sales, licence fees or any other revenue stream will be recorded on the blockchain, ensuring fair and accurate distribution among content creators, be it crew members or artists, as well as investors and funding commissions.</li> 
                                <li><b>Smart Contracts: </b>Smart contracts on the blockchain will automatically execute revenue distribution based on predefined terms. These terms are designed to ensure equitable sharing while adhering to German and European legal requirements.</li> 
                            </ol>
                            </p>
                        </section>


                        <section>
                            <h2>4. Privacy and Data Protection</h2>
                            <p>
                            <ol className={styles.ol} type="a">
                                <li><b>Data Collection: </b>We collect and process personal data as outlined in our Privacy Policy. By using our platform, you consent to such data collection and processing.</li> 
                                <li><b>Consent for Blockchain Technology: </b>You consent to the use of blockchain technology for transparent revenue sharing as described in these Terms of Use.</li> 
                            </ol>
                            </p>
                        </section>

                        <section>
                        <h2>5. Limitation of Liability</h2>
                            <p>
                            We strive to provide a secure and reliable platform, but we cannot guarantee uninterrupted access or the absence of errors. You use our services at your own risk, and we are not liable for any damages resulting from your use of the platform.
                            </p>
                        </section>

                        <section>
                        <h2>6. Indemnification</h2>
                            <p>
                            You agree to indemnify and hold us harmless from any claims, damages, or losses arising from your use of our platform, violation of these Terms of Use, or infringement of third-party rights.
                            </p>
                        </section>

                        <section>
                        <h2>7. Modifications to Terms</h2>
                            <p>
                            We may update these Terms of Use from time to time. Any changes will be communicated to you through the platform. Continued use of our services after changes constitute acceptance of the updated terms.
                            </p>
                        </section>

                        <section>
                        <h2>8. Governing Law and Jurisdiction</h2>
                            <p>
                            These Terms of Use are governed by the laws of Germany. Any disputes arising out of or related to these terms shall be resolved exclusively by the courts of Cologne, Germany.
                            </p>
                        </section>

                        <section>
                        <h2>9. Contact Us</h2>
                        <p>
                        If you have questions, concerns, or feedback regarding these Terms of Use, please contact us at +49.176.80201340 or <a href="mailto:donald.schubert@dgc.org">donald.schubert@dgc.org</a>.<br /><br />

                        Thank you for using KAAATSCHING‼! We're excited to provide you with a transparent and enjoyable customer user experience. <br /> <br />
                        </p>
                        </section>

                        <section>
                            <address>
                            Don Schubert, <br />
                            Managing CEO <br />
                            KAAATSCHING‼! <br />
                            Stolzestr. 10, D-50674 COLOGNE <br />
                            26.08.2023 <br />

                            </address>
                        </section>

                        <section>
                            <h2>Credits</h2>
                            <p>
                            Project Owners: Matthias Nehls & Don Schubert <br />
                            Head Blockchain Developer: Muhammad Rameez <br />
                            {/* Blockchain Developers: Muhammad Taimoor, Jacek Varky, Julian Sauer. <br />
                            Student Assistant Developer: Fares Alazzawi <br />
                            Logo Design: Oliver Koch <br />
                            Translations:  <br />
                            French: Jules Eisenchteter  <br />
                            Portuguese: Clara Kreutter  <br />
                            Spanish: Paolo Vieira  <br /> */}
                            </p>
                        </section>
                        
                        </div>
                        
                    </Stack>
            </Container>
        </Page>
    );
}
